@use '@angular/material' as mat;
@import '@angular/material/theming';
// TODO(v15): As of v15 mat.legacy-core no longer includes default typography styles.
//  The following line adds:
//    1. Default typography styles for all components
//    2. Styles for typography hierarchy classes (e.g. .mat-headline-1)
//  If you specify typography styles for the components you use elsewhere, you should delete this line.
//  If you don't need the default component typographies but still want the hierarchy styles,
//  you can delete this line and instead use:
//    `@include mat.legacy-typography-hierarchy(mat.define-legacy-typography-config());`
@include mat.all-legacy-component-typographies();
@include mat.legacy-core();

$MES-theme-primary: mat.define-palette(mat.$light-blue-palette, 800, 600, 900);
$MES-theme-accent: mat.define-palette(mat.$orange-palette, A200, A100, A400);
$MES-theme-warn: mat.define-palette(mat.$red-palette);

$MES-theme: mat.define-light-theme($MES-theme-primary, $MES-theme-accent, $MES-theme-warn);

$desktop: 960px;
$tablet: 768px;
$mobile-large: 640px;
$mobile: 480px;
$mobile-small: 300px;

@include mat.all-legacy-component-themes($MES-theme);

@import "src/component-styles";
@import "src/styles_flex.scss";
.mat-dialog-actions {
  // ...
  &[align='end'] {
    justify-content: flex-end;
  }

  &[align='center'] {
    justify-content: center;
  }
}

/* You can add global styles to this file, and also import other style files */

@import '@angular/material/prebuilt-themes/indigo-pink.css';
//@import '~@angular/material/prebuilt-themes/deeppurple-amber.css';

body {
  font-family: 'Open Sans', sans-serif;
  margin:0;
}

//.mat-accent .mat-pseudo-checkbox-checked,.mat-accent .mat-pseudo-checkbox-indeterminate,.mat-pseudo-checkbox-checked,.mat-pseudo-checkbox-indeterminate{background:#0D0221}.mat-warn .mat-pseudo-checkbox-checked,.mat-warn .mat-pseudo-checkbox-indeterminate{background:#0D0221}
//.mat-checkbox .mat-ripple-element{background-color:#000}.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element,.mat-checkbox:active:not(.mat-checkbox-disabled).mat-primary .mat-ripple-element{background:#3f51b5}.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element,.mat-checkbox:active:not(.mat-checkbox-disabled).mat-accent .mat-ripple-element{background:#0D0221}.mat-checkbox-checked:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element,.mat-checkbox:active:not(.mat-checkbox-disabled).mat-warn .mat-ripple-element{background:#f44336}

.cdk-overlay-container {
   z-index: 999 !important;
}

.fc-bgevent{
  border: 1px solid green;
  cursor: pointer !important;
}

.fc-bg-event {
  border: 1px solid black !important; // Set the border color on Shift events
}

.fc-event{
  cursor: pointer !important;
  line-height: 2.4;
  //border: none;
}

.fc-bgevent:hover{
  border-width: 3px;
  //border-color: blue !important;
}


.fc-event:hover{
  border-width: 3px;
  //border-color: blue !important;
  //border: none;
}


.cdk-overlay-pane {
  width: 500px !important;
  max-width: 100vw !important;
}

.fc-right {
  display: none;
}


.fc-center{
  padding-bottom: -16px;
}

.mat-h2, .mat-title, .mat-typography h2 {
  margin-bottom: 0 !important;
}


// GEA MES THEMING

.app-margin {
  width: 90%;
  margin-left: 5%;
  margin-right: 5%;
}

.gea-spacer {
  height:20px;
  background-color:white;
}

.gea-help-card {
  background-color:linen;
}

.gea-spacer-bottom {
  height: 50px;
}

// MOBILE FIRST

.gea-button-primary {
  width: 100%;
  height: 60px;
  margin-top: 10px;
  margin-bottom: 10px;
  font-size: large;
}

// DESKTOP

@media only screen and (min-width: $tablet){
  .gea-button-primary {
    width: 400px;
    height: 60px;
    margin-top: 10px;
    margin-bottom: 10px;
    font-size: large;
  }

}